import React from "react"
import styledNormalize from 'styled-normalize';
import { createGlobalStyle } from 'styled-components';

import SEO from "../components/seo"

const GlobalStyle = createGlobalStyle`
  ${styledNormalize}
  body{
    font-family: 'Lato', sans-serif;
  }
`;

const NotFoundPage = () => (
  <>
    <SEO title="..." />
    <GlobalStyle />
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </>
)

export default NotFoundPage
